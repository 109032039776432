<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>

    <div class="enterprise clearfix">
      <div class="left">
        <navleft v-on:cateid="getcateid" :tagnumx="tagnumx" />
      </div>
      <div class="right">
        <div class="ipt">
          <li>
            企业类型：
            <el-select
              class="iipp"
              v-model="valuetype"
              placeholder="请选择"
              @change="labelchange($event, 1)"
            >
              <el-option
                v-for="(item, index) in optionstype"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            企业资质：
            <el-select
              class="iipp"
              v-model="valueaptitude"
              placeholder="请选择"
              @change="labelchange($event, 2)"
            >
              <el-option
                v-for="item in optionsaptitude"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            注册资本:
            <el-select
              class="iipp"
              v-model="valuecapital"
              placeholder="请选择"
              @change="labelchange($event, 3)"
            >
              <el-option
                v-for="item in optionscapital"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            上市状态：
            <el-select
              class="iipp"
              v-model="valuestate"
              placeholder="请选择"
              @change="labelchange($event, 4)"
            >
              <el-option
                v-for="item in optionsstate"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            成立时间：
            <el-select
              class="iipp"
              v-model="valuetime"
              placeholder="请选择"
              @change="labelchange($event, 6)"
            >
              <el-option
                v-for="(item, index) in optionstime"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            融资轮次：
            <el-select
              class="iipp"
              v-model="valuefinanc"
              placeholder="请选择"
              @change="labelchange($event, 7)"
            >
              <el-option
                v-for="(item, index) in optionsfinanc"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li style="display:flex;">
            <div style="width:138px;">公司名称 ：</div>
            <el-input
              v-model="company_name"
              placeholder="请输入公司名称"
            ></el-input>
          </li>
          <li>
            排序:
            <el-select
              class="iipp"
              v-model="valuesorts"
              placeholder="请选择"
              @change="labelchange($event, 8)"
            >
              <el-option
                v-for="(item, index) in optionssorts"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
        </div>
        <div class="zhanshi">
          <li class="lilast">
            <span v-if="type">
              {{ type }}
              <dl @click="(type = ''), (valuetype = ''), (entype = '')">X</dl>
            </span>
            <span v-if="aptitude">
              {{ aptitude }}
              <dl
                @click="
                  (aptitude = ''), (valueaptitude = ''), (qualificat = '')
                "
              >
                X
              </dl>
            </span>
            <span v-if="capital">
              {{ capital }}
              <dl
                @click="(capital = ''), (valuecapital = ''), (capitalid = '')"
              >
                X
              </dl>
            </span>
            <span v-if="state">
              {{ state }}
              <dl @click="(state = ''), (valuestate = ''), (quoted = '')">X</dl>
            </span>
            <span v-if="time">
              {{ time }}
              <dl @click="(time = ''), (valuetime = ''), (yearid = '')">X</dl>
            </span>

            <span v-if="financ">
              {{ financ }}
              <dl @click="(financ = ''), (valuefinanc = ''), (roundid = '')">
                X
              </dl>
            </span>
            <span v-if="valuesorts">
              {{ valuesorts }}
              <dl @click="(valuesorts = ''), (sorts = '')">
                X
              </dl>
            </span>
          </li>
          <span class="seach" @click="querycondition">查询</span>
        </div>
        <div class="maps_box">
          <div class="xuanz_con">
            <div @click="pupshow = true" class="iipp" v-if="secondshow">
              <el-input
                v-model="pupvalue"
                placeholder="选择产业"
                readonly
              ></el-input>
            </div>
            <div class="pos_p">
              全国
              <span v-if="province">> {{ province }}</span>
              <span v-if="city">> {{ city }}</span>
              <span v-if="region">> {{ region }}</span>
              <span v-if="product">>{{ product }}</span>
            </div>
          </div>
          <div class="t-bottom">
            <baidu-map
              class="bm-view"
              :center="center"
              :double-click-zoom="double"
              :zoom="zoom"
              :scroll-wheel-zoom="true"
            >
              <bm-control>
                <button @click="addZoom()">还原</button>
              </bm-control>
              <bm-boundary
                v-for="item in district"
                :title="item.value"
                :key="item.name"
                :name="item.name"
                :strokeWeight="1"
                :strokeColor="item.color ? 'blue' : ''"
                :fillColor="item.color"
                @click="gotocenter(item.jwd, item.name)"
              ></bm-boundary>
              <template v-if="zoom != 7">
                <bm-label
                  v-for="(item, index) in district"
                  :key="index"
                  :content="String(item.value)"
                  :position="item.jwd"
                  :title="item.name + ' ' + item.value"
                  :labelStyle="{ color: 'red', fontSize: '12px' }"
                />
              </template>
              <bm-marker
                v-for="item in alilte"
                :key="item.name"
                :position="item.jwd"
                :dragging="false"
                :title="item.name"
              ></bm-marker>
            </baidu-map>
            <!-- <Map v-on:clickMap="gotocenterMap" v-on:setLevel="setLevel"></Map> -->
          </div>
        </div>
        <div class="zhuzhuang">
          <dl class="qiye_dltop">
            <dt class="ttit">
              <span v-if="!province">全国</span>
              <span v-if="province">{{ province }}</span>
              <span v-if="city">{{ city }}</span>
              <span v-if="region">{{ region }}</span
              >企业数量排名前 {{ citytoplistnum }}
            </dt>
          </dl>

          <div class="echars_box">
            <div ref="main" id="main0" style="width: 150%; height: 300px"></div>
          </div>

          <div class="bottom">
            <dl class="qiye_dltop">
              <dt>企业总数（户）</dt>
              <dd>{{ total }}</dd>
            </dl>
            <ul>
              <li>
                <span>企业名称</span>
                <span>企业热度</span>
              </li>
              <li
                v-for="item in enterprisenum"
                :key="item.id"
                @click="goDetails(item.id)"
              >
                <span :title="item.company_name">{{ item.company_name }}</span>
                <span>
                  <img
                    v-for="i in item.hots"
                    src="../../assets/merchantsradar/zuihuo.png"
                    alt
                  />
                  <img
                    v-for="i in 5 - item.hots"
                    src="../../assets/merchantsradar/zuihuo2.png"
                    alt
                  />
                  <!-- {{ item.hots }}星 -->
                </span>
              </li>
            </ul>

            <div class="fenye">
              <el-pagination
                small
                background
                layout="prev, pager, next"
                :page-size="10"
                :total="Number(total)"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pup" v-if="pupshow">
      <div class="pup-box">
        <ul class="lian_tu_top">
          <li style="width: 33%">
            <img src="../../assets/industr/arrow-left.png" alt />
            上游
            <img src="../../assets/industr/arrow-right.png" alt />
          </li>
          <li style="width: 33%">
            <img src="../../assets/industr/arrow-left.png" alt />
            中游
            <img src="../../assets/industr/arrow-right.png" alt />
          </li>
          <li style="width: 33%">
            <img src="../../assets/industr/arrow-left.png" alt />
            下游
            <img src="../../assets/industr/arrow-right.png" alt />
          </li>
        </ul>
        <div class="clearfix">
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in upstream.subNodeList"
              :key="index"
            >
              <template slot="title"
                ><div @click="getproduct(item.node)">
                  {{ item.node }}
                  <!-- ({{ item.count }}) -->
                </div></template
              >
              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count }}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in midstream.subNodeList"
              :key="index"
            >
              <template slot="title"
                ><div @click="getproduct(item.node)">
                  {{ item.node }}
                  <!-- ({{ item.count }}) -->
                </div></template
              >
              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count }}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in downstream.subNodeList"
              :key="index"
            >
              <template slot="title"
                ><div @click="getproduct(item.node)">
                  {{ item.node }}
                  <!-- ({{ item.count }}) -->
                </div></template
              >
              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count }}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div>
          <el-button @click="pupshow = false">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  condition,
  chaingraph,
  firmlist,
  nationalbar,
  nationalbartop5,
  diagramregion,
  attractsdmap,
} from "@/api/index";
import echarts from "echarts/lib/echarts";
import "echarts/lib/component/graphic";
export default {
  name: "investmentmap",
  data() {
    return {
      company_name: "", // 公司名称
      valuesorts: "",
      sorts: "",
      optionssorts: [
        { label: "时间降序", value: "1" },
        { label: "热度降序", value: "2" },
      ],
      subNodeListshow: false,
      tagnum: 3,
      tagnumx: 3,
      valuetype: "", //企业类型：
      type: "",
      optionstype: [],
      valueaptitude: "", //企业资质
      aptitude: "",
      optionsaptitude: [],
      valuecapital: "", //注册资本
      capital: "",
      optionscapital: [],
      valuestate: "", //上市状态
      state: "",
      optionsstate: [],
      valuetime: "", //成立时间
      time: "",
      optionstime: [],
      valuefinanc: "", //融资轮次
      optionsfinanc: [],
      financ: "",
      total: "",
      inid: "", // 行业id
      page: 1,
      perpage: 10,
      entype: "", //   企业类型id
      qualificat: "", //  企业资质id
      capitalid: "", //      注册资本id
      quoted: "", //   上市板块
      area: ["", "", ""], // 省市区【省市区】
      yearid: "", //成立时间年限
      roundid: "", // 融资轮次id
      product: "", //  产业产品选择（链图上的产品名）
      double: "",
      enterprisenum: [],
      center: { lng: 112.549, lat: 37.957 }, //经纬度
      zoom: 5, //地图展示级别
      color: "blue",
      district: [],
      province: "",
      city: "",
      region: "",
      citydata: [],
      citynum: [],
      pupshow: false,
      pupvalue: "",
      secondshow: true,
      upstream: [],
      midstream: [],
      downstream: [],
      alilte: [],
      citytoplistnum: "",
      chartsPicture: null,
    };
  },
  mounted() {
    this.initPicture();
    this.inid = localStorage.getItem("inidchild");
    this.getcondition();
    this.getchaingraph();
    this.querycondition();
  },
  created() {},
  methods: {
    //新打开一个详情页
    goDetails(id) {
      let href = this.$router.resolve({
        path: "/industrial/chdetsils9",
        query: {
          id: id,
          tagnum: 3,
        },
      });
      // window.open(href.href, "_blank");
      // window.location.href=href.href
      var aaa = "/industrial/chdetsils9/?id=" + id + "&tagnum=" + 3;
      this.$router.push(aaa);
    },
    // 导航条变化
    getcateid(data) {
      this.cate = data.cate;
      this.inid = data.id;
      if (this.cate != 1) {
        this.secondshow = true;
        this.querycondition();
        this.getchaingraph();
      } else {
        this.secondshow = false;
      }
      this.product = "";
      if (this.inid != 8) {
        this.tagnumx = -1;
      }
    },
    // 获取筛选条件
    async getcondition() {
      const res = await condition();
      this.optionstype = res.options.entype;
      this.optionsaptitude = res.options.qualificat;
      this.optionscapital = res.options.capital;
      this.optionsstate = res.options.quoted;
      this.optionstime = res.options.buildate;
      this.optionsfinanc = res.options.financ;
    },
    // 查询
    queryconditionfirmlist() {
      var data = {
        inid: this.inid, // 行业id
        page: this.page,
        perpage: this.perpage,
        entype: this.entype, //   企业类型id
        qualificat: this.qualificat, //  企业资质id
        capital: this.capitalid, //      注册资本id
        quoted: this.quoted, //   上市板块
        area: this.area, // 省市区【省市区】
        yearid: this.yearid, //成立时间年限
        roundid: this.roundid, // 融资轮次id
        product: this.product, //  产业产品选择（链图上的产品名）
        sorts: this.sorts,
        company_name: this.company_name,
      };
      this.getfirmlist(data);
    },
    querycondition() {
      var data = {
        inid: this.inid, // 行业id
        page: this.page,
        perpage: this.perpage,
        entype: this.entype, //   企业类型id
        qualificat: this.qualificat, //  企业资质id
        capital: this.capitalid, //      注册资本id
        quoted: this.quoted, //   上市板块
        area: this.area, // 省市区【省市区】
        yearid: this.yearid, //成立时间年限
        roundid: this.roundid, // 融资轮次id
        product: this.product, //  产业产品选择（链图上的产品名）
        sorts: this.sorts,
        company_name: this.company_name,
      };
      this.getfirmlist(data);
      this.getnationalbar(data);
      this.getBarData(data);
    },
    // 全国企业列表
    async getfirmlist(data) {
      const res = await firmlist(data);
      this.enterprisenum = res.df;
      this.total = res.size;
    },
    //全国色块图
    async getnationalbar(data) {
      this.district = [];
      const res = await nationalbar(data);
      this.district = res;
     console.log(res)
    },
    //右侧柱状图
    async getBarData(data) {
      this.citydata = [];
      this.citynum = [];
      const restop5 = await nationalbartop5(data);
      this.citytoplistnum = restop5.length;
      let newCitynum = [];
      let newCitydata = [];
      restop5.map((item) => {
        newCitydata.push(item.name);
        newCitynum.push(item.value);
      });
      this.citydata = newCitydata;
      this.citynum = newCitynum;

      this.picture();
    },
    // 下钻
    gotocenter(index, name) {
      console.log(index, name);
      if (!this.province) {
        this.zoom = 7;
        this.center = index;
        this.province = name;
        this.area[0] = name;
        this.querycondition();
        this.getchaingraph();
        return false;
      } else if (!this.city) {
        this.zoom = 10;
        this.center = index;
        this.city = name;
        this.area[1] = name;
        this.querycondition();
        this.getchaingraph();
        return false;
      } else if (!this.region) {
        this.zoom = 11;
        this.center = index;
        this.region = name;
        this.area[2] = name;
        this.getattractsdmap();
        this.querycondition();
        this.getchaingraph();
        return false;
      }
    },
    // 下钻
    // gotocenterMap(name) {
    //   console.log(name);
    //   if (!this.province) {
    //     this.zoom = 7;
    //     this.province = name;
    //     this.area[0] = name;
    //     this.querycondition();
    //     this.getchaingraph();
    //     return false;
    //   } else if (!this.city) {
    //     this.zoom = 10;
    //     this.city = name;
    //     this.area[1] = name;
    //     this.querycondition();
    //     this.getchaingraph();
    //     return false;
    //   } else if (!this.region) {
    //     this.zoom = 11;
    //     this.region = name;
    //     this.area[2] = name;
    //     this.getattractsdmap();
    //     this.querycondition();
    //     this.getchaingraph();
    //     return false;
    //   } else if (this.region) {
    //     this.zoom = 11;
    //     this.region = name;
    //     this.area[2] = name;
    //     this.getattractsdmap();
    //     this.querycondition();
    //     this.getchaingraph();
    //     return false;
    //   }
    // },
    // 招商地图散点图
    async getattractsdmap() {
      const res = await attractsdmap({
        inid: this.inid, // 行业id
        entype: this.entype, //   企业类型id
        qualificat: this.qualificat, //  企业资质id
        capital: this.capitalid, //      注册资本id
        quoted: this.quoted, //   上市板块
        area: this.area, // 省市区【省市区】
        yearid: this.yearid, //成立时间年限
        roundid: this.roundid, // 融资轮次id
        product: this.product, //  产业产品选择（链图上的产品名）
        sorts: this.sorts,
        company_name: this.company_name,
      });
      this.alilte = res;
    },
    // setLevel() {
    //   console.log("zoom");
    //   console.log(this.zoom);
    //   if (this.zoom == 7) {
    //     this.zoom = 5;
    //     this.area[0] = "";
    //     this.province = "";
    //     this.alilte = "";
    //     this.querycondition();
    //     return false;
    //   } else if (this.zoom == 10) {
    //     this.zoom = 7;
    //     this.area[1] = "";
    //     this.city = "";
    //     this.alilte = "";
    //     this.querycondition();
    //   } else if (this.zoom == 11) {
    //     this.zoom = 10;
    //     this.area[2] = "";
    //     this.region = "";
    //     this.alilte = "";
    //     this.querycondition();
    //   }
    // },
    addZoom() {
      if (this.zoom == 7) {
        this.zoom = 5;
        this.area[0] = "";
        this.province = "";
        this.alilte = "";
        this.querycondition();
        return false;
      } else if (this.zoom == 10) {
        this.zoom = 7;
        this.area[1] = "";
        this.city = "";
        this.alilte = "";
        this.querycondition();
      } else if (this.zoom == 11) {
        this.zoom = 10;
        this.area[2] = "";
        this.region = "";
        this.alilte = "";
        this.querycondition();
      }
    },
    labelchange(e, num) {
      let obj = {};
      switch (num) {
        case 1:
          obj = this.optionstype.find((item) => {
            return item.value === e; //筛选出匹配数据
          });
          this.type = obj.label;
          this.entype = e;
          break;
        case 2:
          obj = this.optionsaptitude.find((item) => {
            return item.value === e; //筛选出匹配数据
          });
          this.aptitude = obj.label;
          this.qualificat = e;
          break;
        case 3:
          obj = this.optionscapital.find((item) => {
            return item.value === e; //筛选出匹配数据
          });
          this.capital = obj.label;
          this.capitalid = e;
          break;
        case 4:
          obj = this.optionsstate.find((item) => {
            return item.value === e; //筛选出匹配数据
          });
          this.state = obj.label;
          this.quoted = e;
          break;
        case 6:
          obj = this.optionstime.find((item) => {
            return item.value === e; //筛选出匹配数据
          });
          this.time = obj.label;
          this.yearid = e;
          break;
        case 7:
          obj = this.optionsfinanc.find((item) => {
            return item.value === e; //筛选出匹配数据
          });
          this.financ = obj.label;
          this.roundid = e;
          break;
        case 8:
          obj = this.optionssorts.find((item) => {
            return item.value === e; //筛选出匹配数据
          });
          this.valuesorts = obj.label;
          this.sorts = e;
          break;
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      // this.querycondition();
      this.queryconditionfirmlist();
    },
    //获取链图数据
    async getchaingraph() {
      const res = await chaingraph({
        inid: this.inid,
        area: this.area,
      });
      // console.log(res);
      this.upstream = res.nodeList[0];
      this.midstream = res.nodeList[1];
      this.downstream = res.nodeList[2];
    },

    //点击链图列表查询数据
    getproduct(name) {
      this.product = name;
      if (this.subNodeListshow == name) {
        this.subNodeListshow = false;
      } else {
        this.subNodeListshow = name;
      }
      this.querycondition();
    },
    getproductx(name) {
      this.product = name;
      this.querycondition();
    },
    initPicture() {
      this.chartsPicture = this.$echarts.init(this.$refs.main);
      let option = {
        width: "100%",
        color: ["#999"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "6%",
          left: "1%",
          right: "1%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            minInterval: 1,
            type: "category",
            data: this.citydata,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#b3b3b3",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#efefef",
                //坐标轴的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 1000,
            splitNumber: 11,
            splitLine: {
              lineStyle: {
                color: "#efefef",
                //坐标轴的颜色
              },
              show: true,
              //去掉背景的网格线
            },
            axisLabel: {
              textStyle: {
                color: "#b3b3b3",
                //坐标的字体颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#efefef",
                //坐标轴的颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "15",
            barCategoryGap: "30%",
            itemStyle: {
              normal: {
                barBorderRadius: 4,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#3D72FF",
                  },
                  {
                    offset: 0.9,
                    color: "#68B2FF",
                  },
                ]),
              },
            },
          },
        ],
      };
      this.chartsPicture.setOption(option);
    },
    picture() {
      let dataZoom = [];
      if (this.citydata.length != 1) {
        dataZoom = [
          {
            type: "slider", //有单独的滑动条，用户在滑动条上进行缩放或漫游。inside是直接可以是在内部拖动显示
            show: false, //是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
            start: 0, //数据窗口范围的起始百分比0-100
            end: 100, //数据窗口范围的结束百分比0-100
            // xAxisIndex: [0], // 此处表示控制第一个xAxis，设置 dataZoom-slider 组件控制的 x轴 可是已数组[0,2]表示控制第一，三个；xAxisIndex: 2 ，表示控制第二个。yAxisIndex属性同理
            bottom: -10, //距离底部的距离
            height: 30,
          },
          {
            type: "inside",
            // realtime: true,
            start: 0,
            end: 50,
          },
        ];
      }
      // 指定图表的配置项和数据
      let option = {
        xAxis: [
          {
            data: this.citydata,
          },
        ],
        dataZoom: dataZoom,
        series: [
          {
            data: this.citynum,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表的。
      this.chartsPicture.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  padding-bottom: 30px;
  background: rgb(243, 243, 243);

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  margin-top: 20px;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 31px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 34px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
  color: #ffffff;
}
.right {
  background: #fff;
  width: calc(100% - 256px - 20px);
  height: 1196px;
  float: right;
  margin-top: 20px;
  margin-left: 10px;
  .ipt {
    width: 100%;
    margin-bottom: 10px;
    min-height: 60px;
    overflow: hidden;
    zoom: 1;
    li {
      display: block;
      height: 31px;
      width: 227px;
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 1% 1% 0;
      /deep/.el-select {
        width: 57%;
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
      /deep/.el-input {
        width: 100%;
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-icon-circle-close {
          background: url("../../assets/merchantsradar/cuo.png") 50% 50%
            no-repeat;
        }
        .el-icon-circle-close:before {
          content: "";
        }
      }
    }
  }
  .zhanshi {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0% 2% 0;
    .lilast {
      list-style: none;
      span {
        display: block;
        width: 168px;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 31px;
        border: 1px solid #dddddd;
        margin-right: 10px;
        padding-right: 10px;
        margin-top: 10px;
        dl {
          float: right;
          color: #fe6a00;
          cursor: pointer;
        }
      }

      span:nth-child(2) {
        width: 182px;
      }
    }
  }

  .maps_box {
    width: 70%;
    height: 900px;
    float: left;
    margin-top: 20px;

    .xuanz_con {
      overflow: hidden;
      zoom: 1;
      margin: 0 20px 0 20px;

      .pos_p {
        float: left;
        height: 33px;
        line-height: 33px;
        font-size: 14px;
        margin-left: 30px;
        span:last-child {
          color: #fe6a00;
        }
      }
    }
    .iipp {
      /deep/.el-input {
        width: 200px !important;
        height: 30px !important;
        float: left !important;

        input {
          cursor: pointer !important;
          width: 100%;
          height: 30px;
          border-radius: 0;
          background: #ffffff;
          color: #fe6a00;
          border: 1px solid #fe6a00;
        }
        input::-webkit-input-placeholder {
          color: #fe6a00;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
          color: #fe6a00;
        }
      }
      /deep/.el-input {
        width: 100%;
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          color: #fe6a00;
          border: 1px solid #fe6a00;
        }
        /deep/.el-icon-circle-close {
          background: url("../../assets/merchantsradar/cuo.png") 50% 50%
            no-repeat;
        }
      }
    }
    .t-bottom {
      margin-top: 21px;
      margin-left: 9px;
      width: 100%;
      height: 95%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .zhuzhuang {
    width: 27%;
    margin-right: 1%;
    float: right;

    .qiye_dltop {
      height: 44px;
      line-height: 44px;
      background: #17a8fe;
      border-radius: 10px;
      overflow: hidden;
      zoom: 1;
      padding: 0 15px;
      color: #fff;
      margin-bottom: 20px;
      dt {
        float: left;
        font-size: 14px;
      }
      dd {
        float: right;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .echars_box {
      // width: 100%;
      // overflow-x: hidden;
    }
    ::-webkit-scrollbar {
      height: 5px;
      background-color: #eee;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
      // border-radius:7px;
      background: #f3f6fb;
    }

    ::-webkit-scrollbar-thumb {
      // border-radius:7px;  /*滚动条的圆角*/
      background: #d7dce1; /*滚动条的背景颜色*/
    }

    .center {
      width: 92%;
      height: 28%;
      margin: 0 17px 28px 17px;
    }

    .bottom {
      margin-top: 25px;
      position: relative;
      min-height: 500px;
      .ttit {
        display: block;
        width: 100%;
        font-size: 15px;
        text-align: left;
        margin-bottom: 15px;
      }

      ul li {
        display: block;
        height: 40px;
        font-size: 12px;
        line-height: 40px;
        cursor: pointer;
        span {
          display: block;
          width: 20%;
          height: 100%;
          float: left;
          img {
            margin-right: 5px;
          }
        }

        span:nth-child(1) {
          width: 70%;
          text-align: left;
          padding-left: 3%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span:nth-child(2) {
          width: 30%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          cursor: pointer;
        }

        /deep/ .el-rate {
          line-height: 40px;
        }

        /deep/ .el-rate__icon {
          font-size: 12px;
          margin: 0;
        }
      }

      ul li:nth-child(odd) {
        background: #f3f6fb;
      }
      .fenye {
        margin: 20px;
      }
    }
  }
  /deep/.rel-map {
    background-color: transparent;
  }
  /deep/.rel-map-canvas {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent;
  }
  /deep/.rel-node-shape-1 {
    padding: 0;
  }
}
.fenye {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}
.bm-view {
  width: 100%;
  height: 100%;
  /deep/.BMapLabel {
    background-color: transparent !important;
    border: 0 !important;
    color: rgb(121, 22, 22) !important;
  }
}
#main0 {
  width: 100% !important;
}
.pup {
  .pup-box {
    position: fixed;
    width: 930px;
    min-height: 454px;
    padding-bottom: 30px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    .lian_tu_top {
      overflow: hidden;
      zoom: 1;
      margin: 20px 0 20px 20px;
      li {
        list-style: none;
        padding: 0;
        overflow: hidden;
        zoom: 1;
        float: left;
        height: 63px;
        line-height: 63px;
        vertical-align: top;
        text-align: center;
        font-size: 17px;
        background: linear-gradient(90deg, #22baf7, #37e0f7);
        color: #fff;

        img {
          height: 63px;
          display: inline;
          float: left;
          vertical-align: top;
        }
        img:last-child {
          float: right;
        }
      }
    }
    .el-button {
      width: 88px;
      height: 31px;
      background: #ffffff;
      border: 1px solid #fe6a00;
      border-radius: 4px;
      margin-top: 10px;
      padding: 0;
      color: #fe6a00;
      &:nth-child(1) {
        width: 88px;
        height: 31px;
        background: #fe6a00;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }
    }
    //下拉部分
    /deep/.el-collapse {
      border: 0;
      background: #c7e3ff;
      padding: 3%;
      margin-left: 2%;
      width: 30%;
      float: left;
      height: 340px;
      overflow-y: auto;
    }

    /deep/.el-collapse:nth-of-type(2) {
      background: #dbedff;
    }

    /deep/.el-collapse:nth-of-type(3) {
      background: #eef7ff;
    }
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #eee;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
      border-radius: 7px;
      background-color: #eee;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 7px; /*滚动条的圆角*/
      background-color: #9dcaf7; /*滚动条的背景颜色*/
    }

    /deep/.el-collapse-item__header {
      font-size: 18px;
      text-align: left;
      vertical-align: middle;
      background: none;
      font-weight: bold;
      border-bottom: 0;
      // margin-bottom: 15px;
      // height: 36px;
      height: auto;
      // line-height: 36px;
      min-width: 180px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      img {
        margin-right: 6px;
      }
    }
    /deep/.el-collapse-item__wrap {
      background: none;
      text-align: left;
      border-bottom: 0;
      font-weight: 400;
      color: #555;
      padding-left: 30px;
    }
    /deep/.el-collapse-item__content {
      padding-bottom: 0;
      li {
        font-size: 16px;
        // margin-bottom: 15px;
        text-align: left;
        line-height: 30px;
        list-style: none;
        cursor: pointer;
        i {
          transition: all 0.25s;
          &::before {
            display: inline-block;
            content: "\e6e0";
            font-size: 16px;
          }
        }
        ul {
          li {
            font-size: 14px;
            min-height: 24px;
            line-height: 24px;
            margin-left: 20px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
.isactive {
  transform: rotate(90deg);
}
</style>
